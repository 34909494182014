import R from 'ramda';
import { shadeSelectTracking } from '../ShadeFinder/tracking';
import { selectVtoLaunchMethod } from './helpers';
import { eventsNames } from './helpers/eventsConstants';

export const onOpenVto = ({ setIsModalOpen, vtoType }) => {
  if (window && window.YMK) {
    const launchSelectedVtoType = selectVtoLaunchMethod({
      vtoType,
    });

    if (launchSelectedVtoType !== YMK.openShadeFinder) {
      launchSelectedVtoType(true);
    } else {
      launchSelectedVtoType();
    }
  }

  setIsModalOpen(true);
};

export const onCloseButtonClick = ({
  setIsModalOpen,
  setShowStartHero,
  onVtoModalClose,
  setVtoActualProductSlug,
  setVtoActualProductSku,
  eventHandler,
  setShowBuyNowButton,
  setShowEmailSharingButton,
}) => {
  setIsModalOpen(false);
  setShowStartHero(true);
  setVtoActualProductSlug({ actualProductSlug: '' });
  setVtoActualProductSku({ actualProductSku: '' });
  setShowBuyNowButton({ showBuyNowButton: false });
  setShowEmailSharingButton({ showEmailSharingButton: false });
  eventHandler({ eventName: eventsNames.VTO_EVENT_CLOSED });

  if (onVtoModalClose) {
    onVtoModalClose();
  }
};

export const onHeroButtonClick = ({ setShowStartHero, eventHandler }) => {
  setShowStartHero(false);

  eventHandler({
    eventName: 'vto-generic-event-get-started',
  });
};

export const onSetProductID =
  ({ setCart, activeTab, cart, selectedProduct }) =>
  ({ sku, swatches, setVtoActualProductSku, vtoType }) => {
    const internalSelectedProductSwatch = R.find(
      R.propEq('sku', sku),
      swatches
    );

    setVtoActualProductSku({ actualProductSku: sku });
    YMK.applyMakeupBySku(sku);

    const cartProduct = {
      ...R.pick(['title', 'image', 'productUrl'], selectedProduct),
      ...R.pick(['itemName', 'skuColor', 'sku'], internalSelectedProductSwatch),
    };

    setCart({
      cart: R.prop('sku', cartProduct)
        ? {
            ...cart,
            [activeTab]: cartProduct,
          }
        : R.omit([activeTab], cart),
    });

    if (sku) {
      shadeSelectTracking({
        shadeName: R.prop('itemName', internalSelectedProductSwatch),
        vtoType,
      });
    }
  };

export const getTrackingProduct = ({ selectedProductSwatch, swatches }) => {
  const swatchIndex = R.findIndex(
    R.propEq('sku', R.prop('sku', selectedProductSwatch)),
    swatches
  );

  return {
    item_id: R.prop('sku', selectedProductSwatch),
    item_name: R.prop('itemName', selectedProductSwatch),
    index: swatchIndex,
  };
};
